import * as React from 'react';

/**
 * Hook that will be used only at component mount render.
 * @param callback Callback function that will be used on mount render.
 * @param deps Dependency array for useEffect.
 * @param onUnmount Function that will be used on unmount.
 */
const useWhenMounted = (callback: () => void, deps: Array<any>, onUnmount?: () => void): void => {
  const isMountRender = React.useRef(true);

  React.useEffect(() => {
    let isMounted = true;
    if (isMountRender.current) {
      if (isMounted) callback();
      isMountRender.current = false;
    }

    return (): void => {
      onUnmount && onUnmount();
      isMounted = false;
    };
  }, [callback, deps, onUnmount]);
};

interface IFetchResult {
  loading: boolean;
  value?: any;
  error?: Error;
}

/**
 * Hook that will do fetching in a secure way for a mounted component.
 * @param fetch Function for fetching data with an api call.
 * @returns loading: Boolean to tell if fetching is still in progress, value: The result value of fetch, error: Catched error.
 */
const useFetch = (fetch: () => Promise<any>): IFetchResult => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);

    fetch()
      .then((r: Response) => {
        return r.json();
      })
      .then((v: any) => {
        isMounted && setValue(v);
      })
      .catch((e: Error) => {
        isMounted && setError(e);
      })
      .finally(() => {
        isMounted && setLoading(false);
      });

    return (): void => {
      isMounted = false;
    };
  }, [fetch]);

  return { loading: loading, value: value, error: error };
};

interface IDispatchResult {
  loading: boolean;
  promiseResult?: any;
  error?: Error;
}

/**
 * Hook that will do dispathing in a secure way for a mounted component.
 * @param dispatch Function for dispatching
 * @returns loading: Boolean to tell if fetching is still in progress, promiseResult: The result of the promise, error: Catched error.
 */
const useDispatch = (dispatch: () => Promise<any>): IDispatchResult => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [promiseResult, setPromiseResult] = React.useState<any>();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch()
      .then((r: any) => {
        isMounted && setPromiseResult(r);
      })
      .catch((e: Error) => {
        isMounted && setError(e);
      })
      .finally(() => {
        isMounted && setLoading(false);
      });

    return (): void => {
      isMounted = false;
    };
  }, [dispatch]);

  return { loading: loading, promiseResult: promiseResult, error: error };
};

interface IUseTabulationProps {
  currentIdStart: string;
  childrenLength: number;
}

/**
 * Manage tab-based focus navigation within a table-like component.
 * Supports navigating across rows, columns, and sub-elements within cells.
 *
 * @param {string} currentIdStart - Initial focus position ID (e.g., "00").
 * @param {number} childrenLength - Number of rows or children for navigation.
 *
 * @returns {Object} - Contains:
 * - `currentId` (string): Current focus position.
 * - `setCurrentId` (function): Updates the focus position.
 * - `inputRefs` (React.RefObject): Map of input refs by ID.
 * - `handleFocus` (function): Focuses based on row, column, sub positions.
 * - `onKeyDown` (function): Handles Tab/Shift+Tab key navigation.
 * - `subIds` (number[]): Helper ids used to identify sub-elements. Currently limited to 10.
 *
 */
export const useTabulationTools = ({ currentIdStart, childrenLength }: IUseTabulationProps) => {
  const [currentId, setCurrentId] = React.useState<string>(currentIdStart);
  const inputRefs = React.useRef<{ [key: string]: React.RefObject<HTMLInputElement> }>({});

  const parseTarget = (id?: string) => {
    const row = parseInt((id ?? currentId)[0], 10);
    const col = parseInt((id ?? currentId)[1], 10);
    const sub = (id ?? currentId)[2] ? parseInt((id ?? currentId)[2], 10) : undefined;
    return { row, col, sub };
  };

  const handleFocus = (event: React.KeyboardEvent | undefined, row: number, col: number, sub?: number) => {
    const nextId = `${row}${col}${sub ?? ''}`;
    const nextRef = inputRefs.current[nextId];

    if (nextRef.current) {
      const { dataset } = 'node' in nextRef.current ? (nextRef.current.node as TAnyObject) : nextRef.current;
      const isDisabled =
        dataset && typeof dataset === 'object' && 'isDisabled' in dataset
          ? dataset.isDisabled === 'true'
            ? true
            : false
          : false;

      if (!isDisabled) {
        setCurrentId(nextId);
        if (event) event.preventDefault();
        nextRef?.current?.focus();
      }
    }
  };

  const handleClick = (event: MouseEvent) => {
    if (inputRefs.current) {
      const clickedInputRefKey = Object.keys(inputRefs.current).find(
        (k) => inputRefs?.current?.[k]?.current === event.target,
      );
      if (clickedInputRefKey) {
        setCurrentId(clickedInputRefKey);
        const { row, col, sub } = parseTarget(clickedInputRefKey);
        handleFocus(undefined, row, col, sub);
      } else {
        setCurrentId(currentIdStart);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick, false);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, []);

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { row, col, sub } = parseTarget();

    let subs: number[];
    let modifier: number;
    let rowStart: number;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        subs = subIds.slice().reverse();
        modifier = -1;
        rowStart = childrenLength - 1;
      } else {
        subs = subIds;
        modifier = 1;
        rowStart = parseInt(currentIdStart[0], 10);
      }

      const nextRowSub = subs.find((id) => inputRefs.current[`${row + modifier}${col}${id}`]);
      const nextColumnSub = subs.find((id) => inputRefs.current[`${rowStart}${col + modifier}${id}`]);

      switch (true) {
        // Focus on sub element on current row
        case Boolean((sub || sub === 0) && inputRefs.current[`${row}${col}${sub + modifier}`]):
          handleFocus(event, row, col, sub! + modifier);
          break;
        // Focus on sub element on next row
        case Boolean(nextRowSub || nextRowSub === 0):
          handleFocus(event, row + modifier, col, nextRowSub);
          break;
        // Focus on element on next row
        case Boolean(inputRefs.current[`${row + modifier}${col}`]):
          handleFocus(event, row + modifier, col);
          break;
        // Focus on sub element on next column
        case Boolean(nextColumnSub || nextColumnSub === 0):
          handleFocus(event, rowStart, col + modifier, nextColumnSub);
          break;
        // Focus on element on next column
        case Boolean(inputRefs.current[`${rowStart}${col + modifier}`]):
          handleFocus(event, rowStart, col + modifier);
          break;
        default:
          break;
      }
    }
  };

  const subIds = [...Array(10).keys()];

  return {
    currentId,
    setCurrentId,
    inputRefs,
    parseTarget,
    handleFocus,
    onKeyDown,
    subIds,
  };
};

const myHooks = { useWhenMounted, useFetch, useDispatch };

export default myHooks;
