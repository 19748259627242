import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';

import { TDocument, isMGCComplete, mgcScore } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const getLatestMGC = (
  docs: Array<TDocument>,
): { date: PartialDate | undefined; value: JSX.Element | string | undefined } | undefined => {
  const latest = docs.filter((d) => d._type === 'mgc').sort((n1, n2) => sortPartialDate(n2.date, n1.date))[0] as IMGC;
  if (!latest) return undefined;
  const dateOfLatest = latest.date;
  const valueOfLatest = isMGCComplete(latest)
    ? mgcScore(latest).toString()
    : latest.manualScore || latest.manualScore === 0
      ? latest.manualScore.toString()
      : '-';
  return {
    date: dateOfLatest,
    value: valueOfLatest,
  };
};

const FunctionalPerformanceMgravisDash = ({ documents }: IOwnProps): JSX.Element => {
  const mgc = getLatestMGC(documents);

  return (
    <React.Fragment>
      <TileContentMaker
        type="specificList"
        data={[
          {
            date: isPartialDate(mgc?.date) ? formatPartialDate(mgc?.date) : '',
            title: fm('functionalPerformanceMgravis.mgcShort'),
            value: !mgc ? fm('functionalPerformanceMgravis.notEvaluated') : mgc?.value,
            key: 'mgc',
          },
        ]}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<TDocument>;
}

export default FunctionalPerformanceMgravisDash;
