import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';

const mgravisData = (fm: (id: string) => string): IDiseaseHistoryData => {
  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  return {
    graphData: {
      common: {
        mgc: {
          data: [],
          title: 'MGC',
          scale: {
            type: 'linear',
            linearScale: [0, 50],
          },
        },
      },
      selfReporting: {
        eq5d: {
          data: [],
          title: 'EQ-5D-5L',
          scale: {
            type: 'hybridCL',
            linearScale: [0, 1],
            customScale: ['< 0'],
          },
        },
        mgadl: {
          data: [],
          title: 'MG-ADL',
          scale: {
            type: 'linear',
            linearScale: [0, 24],
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      passQuestion: {
        id: 'passQuestion',
        type: 'single',
        name: fm('myService.mgravis.passQuestion'),
        addons: [],
      },
      earlySymptoms: {
        id: 'earlySymptoms',
        type: 'single',
        name: fm('diagnosis.mgravis.symptoms.title'),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('diagnosis.title'),
        addons: [],
      },
      hospitalizations: {
        id: 'hospitalization',
        type: 'expansionPanel',
        name: fm('comorbidity.mgravis.title'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['mgravis'].map((code) => {
            return { title: `${code} - ${fm(`graph.atcOpts.mgravis.${code}`)}` };
          }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('graph.endedMedications'),
        addons: [],
      },
      procedures: {
        id: 'procedures',
        type: 'expansionPanel',
        name: fm('procedure.title'),
        addons: [],
      },
      pregnancies: {
        id: 'pregnancies',
        type: 'single',
        name: fm('graph.pregnancies'),
        addons: [],
      },
      imaging: {
        id: 'imaging',
        type: 'single',
        name: fm('imaging.title'),
        addons: [],
      },
      cnpExaminations: {
        id: 'cnpExaminations',
        type: 'expansionPanel',
        name: fm('clpAndCnpTests.mgravis.title'),
        addons: [],
      },
      diagnosticTests: {
        id: 'diagnosticTests',
        type: 'expansionPanel',
        name: fm('diagnosticTests.title'),
        addons: [],
      },
    },
  };
};

export default mgravisData;
